<template>
	<modal class="NoxModalTeam" name="NoxModalTeam" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'getTeamInvitedUsers'" v-html="$store.getters.getLanguageText('5.24', 0, { id: noxId })"></span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxType == 'getTeamInvitedUsers'">
							<div class="nox_modal_info_team_invited_users" v-if="noxTeamInvitedUsers.length">
								<div class="nox_modal_info_team_invited_users_block" v-for="(value, index) in noxTeamInvitedUsers" :key="index">
									<div class="nox_modal_info_team_invited_users_block_item" :class="[(!value.is_subscription ? 'item_red' : (value.group == 4 ? 'item_grey' : (!value.is_qualification ? 'item_orange' : '')))]">
										<div class="nox_modal_info_team_invited_users_block_item_number">{{ (index + 1) }}</div>
										<div class="nox_modal_info_team_invited_users_block_item_avatar">
											<router-link :to="'/account/client_program/' + value.key" v-if="value.key"><div class="nox_modal_info_team_invited_users_block_item_avatar_img"><img :src="$parent.$parent.$parent.getAvatarByLink(value.avatar)"></div></router-link>
											<div class="nox_modal_info_team_invited_users_block_item_avatar_img" v-else><img :src="$parent.$parent.$parent.getAvatarByLink(value.avatar)"></div>
											<div class="nox_modal_info_team_invited_users_block_item_avatar_status" :style="{ background: '#' + $store.state.noxSystemSettings['user_status_color_' + value.status] }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 117, { status: $store.getters.getLanguageText('4.3.1', value.status), count: $store.state.noxSystemSettings['user_status_cycles_' + value.status] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="value.status">{{ $store.state.noxSystemSettings['user_status_name_' + value.status] }}</div>
										</div>
										<div class="nox_modal_info_team_invited_users_block_item_status" v-html="$store.getters.getLanguageText('3.5.1', (!value.is_subscription ? 3 : (value.group == 4 ? 4 : (value.is_qualification ? 1 : 2))))"></div>
										<div class="nox_modal_info_team_invited_users_block_item_table">
											<table class="nox_table">
												<tbody>
													<tr>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 13) + ':'">{{ value.id }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 14) + ':'"><router-link :to="'/account/client_program/' + value.key" v-if="value.key">{{ (value.username ? value.username : '-') }}</router-link><span v-else>{{ (value.username ? value.username : '-') }}</span></td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 71) + ':'">{{ value.sponsor_id }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 68) + ':'">{{ $store.state.noxSystemSettings['package_name_x' + value.package] }}</td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 67) + ':'"><span :class="[(!value.count_of_completed_quests ? 'sticker sticker_r_w' : '')]">{{ (value.count_of_completed_quests > $store.state.noxSystemSettings.count_of_available_quests ? $store.state.noxSystemSettings.count_of_available_quests : value.count_of_completed_quests) }} / {{ $store.state.noxSystemSettings.count_of_available_quests }}</span></td>
														<td :data-label="$store.getters.getLanguageText('1.7.1', 70) + ':'">{{ value.count_of_invited_users }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div v-else v-html="$store.getters.getLanguageText('5.24', 1)"></div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common" @click="axios" v-if="noxType == 'getTeamInvitedUsers' && noxTeamInvitedUsersLimitOffset" v-html="$store.getters.getLanguageText('1.1', 20)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxId: 0,
			noxType: '',
			noxTeamInvitedUsers: [],
			noxTeamInvitedUsersTableOffset: 0,
			noxTeamInvitedUsersLimitOffset: 0,
			noxTeamInvitedUsersLimitCount: 10,
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxTeamInvitedUsers = [];
				this.noxTeamInvitedUsersTableOffset = 0;
				this.noxTeamInvitedUsersLimitOffset = 0;
				this.noxTeamInvitedUsersLimitCount = 10;
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				this.axios();
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError1: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.23', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.23', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.23', 2); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError2: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 3); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 4); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 5); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 6); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 7); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 8); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 9); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 10); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 11); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3', 12); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'getTeamInvitedUsers') {
						_this.noxIsLoading = true;
						_this.noxIsOpenLoading = !_this.noxTeamInvitedUsersLimitOffset ? true : false;
						config.url = '/v2/account/users/team/invited/users';
						config.params = { sponsor_id: _this.noxId, tableOffset: _this.noxTeamInvitedUsersTableOffset, limitOffset: _this.noxTeamInvitedUsersLimitOffset, limitCount: _this.noxTeamInvitedUsersLimitCount };
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'getTeamInvitedUsers') {
									if (data.data.data.length) {
										for (var i in data.data.data) {
											_this.noxTeamInvitedUsers.push(data.data.data[i]);
										}
									}
									_this.noxTeamInvitedUsersTableOffset = data.data.info.max_id;
									_this.noxTeamInvitedUsersLimitOffset = data.data.info.count > _this.noxTeamInvitedUsersLimitOffset + _this.noxTeamInvitedUsersLimitCount ? _this.noxTeamInvitedUsersLimitOffset + _this.noxTeamInvitedUsersLimitCount : 0; return false;
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'getTeamInvitedUsers') {
										if      (data.response.data.error == 'SPONSOR_ID_IS_EMPTY') { _this.noxAlert = _this.getError1(1); }
										else if (data.response.data.error == 'SPONSOR_ID_NOT_VALID') { _this.noxAlert = _this.getError1(2); }
										else if (data.response.data.error == 'LIMIT_OFFSET_IS_EMPTY') { _this.noxAlert = _this.getError2(1); }
										else if (data.response.data.error == 'LIMIT_OFFSET_NOT_VALID') { _this.noxAlert = _this.getError2(2); }
										else if (data.response.data.error == 'LIMIT_COUNT_IS_EMPTY') { _this.noxAlert = _this.getError2(3); }
										else if (data.response.data.error == 'LIMIT_COUNT_NOT_VALID') { _this.noxAlert = _this.getError2(4); }
										else if (data.response.data.error == 'SORT_FIELD_IS_EMPTY') { _this.noxAlert = _this.getError2(5); }
										else if (data.response.data.error == 'SORT_FIELD_NOT_VALID') { _this.noxAlert = _this.getError2(6); }
										else if (data.response.data.error == 'SORT_ORDER_IS_EMPTY') { _this.noxAlert = _this.getError2(7); }
										else if (data.response.data.error == 'SORT_ORDER_NOT_VALID') { _this.noxAlert = _this.getError2(8); }
										else if (data.response.data.error == 'TABLE_OFFSET_IS_EMPTY') { _this.noxAlert = _this.getError2(9); }
										else if (data.response.data.error == 'TABLE_OFFSET_NOT_VALID') { _this.noxAlert = _this.getError2(10); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'getTeamInvitedUsers') {
										if (data.response.data.error == 'SPONSOR_ID_NOT_FOUND') { _this.noxAlert = _this.getError1(3); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError1(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalTeam');
			}
		}
	}
</script>
